
import { Vue, Component } from 'vue-property-decorator'
import Main from '@/layouts/Main.vue'
import CompaniesList from '@/components/CompaniesList.vue'

@Component({
  components: { Main, CompaniesList },
  metaInfo: {
    title: 'Companies',
  },
})
export default class Companies extends Vue {}
