
import { Vue, Component } from 'vue-property-decorator'
import { CustomerAccount } from '@/models/dto/CustomerAccount'
import { DataTableColumn } from '@/models/DataTableColumn'
import { EventBus } from '@/utils/eventBus'
import { RawLocation } from 'vue-router'
import { TableAction } from '@/models/TableAction'
import { countryPhoneFormatFilter, phoneFormatFilter } from '@/utils/string'
import CUCollectionTable from '@/components/CUCollectionTable.vue'
import CUDataTableLink from '@/components/CUDataTableLink.vue'
import CompanySidebarDetail from '@/components/CompanySidebarDetail.vue'
import sidebar from '@/store/modules/sidebar'
import CreateContactSidebar from '@/components/CreateContactSidebar.vue'
import HoldUpModal from '@/components/HoldUpModal.vue'
import client from '@/services/customerAccount'

@Component({
  components: { HoldUpModal, CUCollectionTable },
})
export default class CompaniesList extends Vue {
  tableView = client.tableView
  tableItemCount = 0
  isModalOpen = false
  selectedCompanies: CustomerAccount[] = []

  handleUpdateCount(count: number): void {
    this.tableItemCount = count
  }

  handleCompanySelected(customerAccount: CustomerAccount): void {
    sidebar.push({
      component: CompanySidebarDetail,
      props: {
        customerAccountId: customerAccount.customerAccountId,
      },
      fab: true,
      on: { refresh: () => EventBus.$emit('refresh-tableview') },
    })
  }

  handleDeleteCompanies(companies: CustomerAccount[]): void {
    const snackbarMessage = 'Companies deleted successfully!'
    const promises = companies
      .map(({ customerAccountId }) => customerAccountId)
      .map(client.delete)
    Promise.all(promises)
      .then(() => EventBus.$emit('refresh-tableview'))
      .then(() => EventBus.$emit('snackbar:success', snackbarMessage))
      .finally(() => (this.selectedCompanies = []))
  }

  deleteCompanies(companies: CustomerAccount[]): void {
    this.isModalOpen = true
    this.selectedCompanies = companies
  }

  actions: TableAction[] = [
    {
      displayText: 'Delete',
      key: 'delete',
      icon: 'delete',
      action: this.deleteCompanies,
    },
  ]

  columns: DataTableColumn[] = [
    {
      _t_id: '20ddecac-fc95-451f-a9a2-fa43374c2b4a',
      columnSlotComponent: CUDataTableLink,
      clickEvent: 'companies:company-selected',
      linkLocation: (row: CustomerAccount): RawLocation => ({
        name: 'companies.detail',
        params: { id: String(row.customerAccountId) },
      }),
      text: 'Company',
      value: 'name',
      filterBySearch: true,
      filterProp: 'name',
    },
    {
      _t_id: '4b397321-397a-4427-b690-a5e8e128f585',
      text: 'Phone',
      value: 'phone',
      computedText: (row): string =>
        countryPhoneFormatFilter(row.phone, row.phoneCountryKey),
    },
    {
      _t_id: '6dd70015-2d4f-4add-b7b0-85a87fb83067',
      text: 'Email',
      value: 'email',
      filterBySearch: true,
      filterProp: 'email',
    },
    {
      _t_id: '4a441a18-a302-42da-b585-aab624c4b7c3',
      text: 'Address',
      value: 'address/street1',
    },
    {
      _t_id: '33884f91-b6d4-4d08-ada6-d4dd972b2e40',
      text: 'City',
      value: 'address/city',
    },
    {
      _t_id: '58cca3b3-27ba-4b77-854f-162aca06db59',
      text: 'State',
      value: 'address/state',
    },
    {
      _t_id: '2ab7b64d-4288-4609-8fde-39fdca2b63b9',
      text: 'Industry',
      value: 'industry/label',
    },
  ]

  handleAddCompany(): void {
    sidebar.push({
      component: CreateContactSidebar,
      props: {
        companyOnly: true,
        preventUpdateCustomer: true,
        displayBulkImport: true,
      },
      on: {
        'company:created': () => {
          sidebar.pop()
          EventBus.$emit('refresh-tableview')
        },
        submit: (id: number) =>
          this.$router.push({
            name: 'companies.detail',
            params: { id: String(id) },
          }),
      },
    })
  }

  mounted(): void {
    EventBus.$on('companies:company-selected', this.handleCompanySelected)
  }

  beforeDestroy(): void {
    EventBus.$off('companies:company-selected', this.handleCompanySelected)
  }
}
